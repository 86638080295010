<template>
  <div class="card WidgetDetail">
    <div class="row">
      <div class="col-lg-4">
        <div class="input-wrap">
          <label for="title">{{ t('title') }}</label>
          <input
            class="form-control"
            v-model="state.headerTitle"
            type="text"
            :disabled="headerTitleDisabled"
            name="title"
            id="title"
          />
        </div>
      </div>

      <div class="col-lg-4">
        <div class="input-wrap">
          <label for="subTitle">{{ t('rightTitle') }}</label>
          <input
            class="form-control"
            v-model="state.headerRightTitle"
            type="text"
            :disabled="headerRightTitleDisabled"
            name="subTitle"
            id="subTitle"
          />
        </div>
      </div>
      <div class="col-lg-4">
        <TagsInput
          :items="collections"
          :label="t('headerRightShowcaseCollections')"
          v-model="state.headerRightShopifyCollectionId"
          :disabled="!state.headerVisible || !state.headerRightVisible"
        />
      </div>

      <div class="col-lg-4 mb-3" style="display: flex">
        <div class="input-wrap">
          <MySwitch
            v-model="state.headerVisible"
            :label="t('headerIsVisible')"
          />
        </div>
      </div>

      <div class="col-lg-4 mb-3" style="display: flex">
        <div class="input-wrap">
          <MySwitch
            :disabled="headerRightVisibleDisabled"
            v-model="state.headerRightVisible"
            :label="t('rightHeaderIsVisible')"
          />
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <div class="input-wrap">
              <div>
                <label class="mb-2">{{ t('type') }}</label>
              </div>
              <a-radio-group v-model:value="state.sourceType">
                <a-radio value="SHOPIFY_COLLECTION"
                >{{ t('collection') }}
                </a-radio>
                <a-radio value="SHOPIFY_PRODUCTS">{{ t('product') }}</a-radio>
              </a-radio-group>
            </div>
          </div>
          <div
            class="col-lg-6"
            v-if="state.sourceType === 'SHOPIFY_COLLECTION'"
          >
            <div class="input-wrap">
              <TagsInput
                :items="collections"
                :label="t('showcaseCollections')"
                v-model="state.sourceData.shopifyCollectionId"
              />
            </div>
          </div>

          <div class="col-lg-6" v-if="state.sourceType === 'SHOPIFY_PRODUCTS'">
            <div class="input-wrap">
              <label>{{ t('products') }}</label>
              <a-select
                mode="tags"
                v-model:value="state.sourceData.shopifyProductIds"
                :max-tags="4"
              >
                <a-select-option
                  :value="item.id"
                  v-for="item in products"
                  :key="item.id"
                >
                  <img
                    style="width: 100px; height: 100px; object-fit: contain"
                    :src="item.image_url"
                  />
                  {{ item.title }}
                </a-select-option>
              </a-select>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="input-wrap">
              <label>{{ t('itemCount') }}</label>
              <a-radio-group v-model:value="state.itemCount">
                <a-radio :value="2">2</a-radio>
                <a-radio :value="4">4</a-radio>
              </a-radio-group>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="input-wrap">
              <label>{{ t('sort.sort') }}</label>
              <a-select
                ref="select"
                v-model:value="state.sortKey"
                style="width: 100%"
              >
                <a-select-option value="DEFAULT"
                >{{ t('sort.default') }}
                </a-select-option>
                <a-select-option value="CREATED_AT_DESC"
                >{{ t('sort.backToOld') }}
                </a-select-option>
                <a-select-option value="PRICE_ASC"
                >{{ t('sort.increasedPrice') }}
                </a-select-option>
                <a-select-option value="PRICE_DESC"
                >{{ t('sort.decreasingPrice') }}
                </a-select-option>
                <a-select-option value="TITLE_DESC"
                >{{ t('sort.alphabeticalAZ') }}
                </a-select-option>
                <a-select-option value="TITLE_ASC"
                >{{ t('sort.alphabeticalZA') }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-lg-6 mt-3">
            <div class="input-wrap">
              <a class="btn btn-danger" @click="cancel">{{ t('cancel') }}</a>
            </div>
          </div>
          <div class="col-lg-6 mt-3">
            <div class="input-wrap">
              <a @click="onSubmit" :disabled="isFormInvalid || onSubmitDisable" class="btn btn-success">{{
                  t('save')
                }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import MySwitch from '../../MySwitch'
import TagsInput from '../../TagsInput'
import useGlobal from '../../../composables/useGlobal'
import { requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import EditLayout1Request from '../../../service/RestApi/ShowcaseRequest/Edit/EditLayout1Request'

export default {
  components: {
    TagsInput,
    MySwitch,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    collections: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { t, restApi, route, router, successNotify, errorNotify, emitter } = useGlobal()

    const showcaseItem = reactive(props.item)

    const onSubmitDisable = ref(false)

    const state = reactive({
      headerTitle: showcaseItem.header.title,
      headerVisible: showcaseItem.header.visible,
      headerRightTitle: showcaseItem.header.right.title,
      headerRightVisible: showcaseItem.header.right.visible,
      headerRightShopifyCollectionId:
      showcaseItem.header.right.data.shopify_collection_id,
      itemCount: showcaseItem.body.item_count,
      sortKey: showcaseItem.body.sort_key,
      sourceType: showcaseItem.body.source_type,
      sourceData: {
        shopifyCollectionId: showcaseItem.body.shopify_collection_id,
        shopifyProductIds: showcaseItem.body.shopify_product_ids,
      },
    })

    const payload = reactive({
      id: showcaseItem.id,
      is_active: showcaseItem.is_active,
      type: showcaseItem.type,
      header: {
        title: state.headerTitle,
        visible: state.headerVisible,
        right: {
          title: state.headerRightTitle,
          visible: state.headerRightVisible,
          type: showcaseItem.header.right.type,
          data: {
            shopify_collection_id: state.headerRightShopifyCollectionId,
          },
        },
      },
      body: {
        item_count: state.itemCount,
        ratio: showcaseItem.body.ratio,
        items: showcaseItem.body.items,
      },
    })

    watch(state, () => {
      payload.header.title = state.headerTitle
      payload.header.visible = state.headerVisible
      payload.header.right.title = state.headerRightTitle
      payload.header.right.visible = state.headerRightVisible
      payload.header.right.data.shopify_collection_id =
        state.headerRightShopifyCollectionId
      payload.body.item_count = state.itemCount

      emitter.emit('showcase-item-updated-sync', payload)

      onSubmitDisable.value = false
    })

    watch(
      () => state.sortKey,
      (newSortKey) => {
        if (newSortKey === 'SHOPIFY_COLLECTION') {
          restApi.sortProducts(
            state.sourceData.shopifyCollectionId,
            state.itemCount,
            newSortKey,
          ).then((response) => {
            payload.body.items = response.data.items

            emitter.emit('showcase-item-updated-sync', payload)

          })
        }
      },
    )

    watch(
      () => state.sourceData.shopifyCollectionId,
      (shopifyCollectionId) => {
        restApi.getProductsByCollectionsId(shopifyCollectionId, 'LAYOUT1').then((products) => {
          payload.body.items = products

          emitter.emit('showcase-item-updated-sync', payload)

        })
      },
    )

    watch(
      () => state.sourceData.shopifyProductIds,
      (shopifyProductIds) => {
        if (state.sourceData.shopifyProductIds.length > state.itemCount) {
          state.sourceData.shopifyProductIds.splice(state.itemCount, state.itemCount)
          errorNotify(
            t('maxItemCount'),
            t('maxItemCountDescription'),
          )
        } else {
          payload.body.items = products.value.filter((product) =>
            shopifyProductIds.includes(product.id),
          )
        }

      },
    )

    const headerTitleDisabled = computed(() => {
      return ! state.headerVisible
    })

    const headerRightTitleDisabled = computed(() => {
      return ! state.headerVisible || ! state.headerRightVisible
    })

    const headerRightVisibleDisabled = computed(() => {
      return ! state.headerVisible
    })

    const products = ref([])

    const isFormInvalid = computed(() => {
      return $v.value.$invalid
    })

    const isHeaderTitleInvalid = computed(() => {
      return $v.value.header.title.$error
    })

    const isHeaderRightTitleInvalid = computed(() => {
      return $v.value.header.right.title.$error
    })

    const rules = {
      headerTitle: [requiredIf(() => state.headerVisible)],
      headerRightTitle: [requiredIf(() => state.headerRightVisible && state.headerVisible)],
      headerRightShopifyCollectionId: [requiredIf(() => state.headerRightVisible && state.headerVisible)],
      sourceData: {
        shopifyCollectionId: [requiredIf(() => state.sourceType === 'SHOPIFY_COLLECTION')],
        shopifyProductIds: [requiredIf(() => state.sourceType === 'SHOPIFY_PRODUCTS')],
      },
    }

    const $v = useVuelidate(rules, state, { $autoDirty: true })

    const onSubmit = () => {
      const editLayout1Request = new EditLayout1Request(
        route.params.id,
        state.headerTitle,
        state.headerVisible,
        state.headerRightTitle,
        state.headerRightVisible,
        state.headerRightShopifyCollectionId,
      ).setBody(
        state.sortKey,
        state.sourceType,
        state.itemCount,
        state.sourceData.shopifyCollectionId,
        state.sourceData.shopifyProductIds,
      ).toParams()

      restApi.editShowcase(editLayout1Request).then(() => {

        successNotify(t('changesSaved'), t('changesSavedDescription'))

        emitter.emit('refresh-simulation')
        onSubmitDisable.value = true
      }).catch((error) => {
        onSubmitDisable.value = true

        errorNotify(t('changesNotSaved'), t('changesNotSavedDescription') + error)

      })
    }

    const cancel = () => {
      emitter.emit('refresh-simulation')
      router.replace({ name: 'HomePage.Allshowcases.Page' })
    }

    onMounted(() => {
      restApi.fetchProducts().then((results) => {
        products.value = results.response.items
      }).catch(() => {
        errorNotify(t('productsNotFetched'), t('productsNotFetchedDescription'))
      })
    })

    return {
      t,
      restApi,
      onSubmit,
      cancel,
      products,
      isFormInvalid,
      isHeaderTitleInvalid,
      isHeaderRightTitleInvalid,
      state,
      headerTitleDisabled,
      headerRightTitleDisabled,
      headerRightVisibleDisabled,

      onSubmitDisable,
    }
  },
}
</script>

<style scoped>
.plus,
.minus {
  padding: 1px 8px;
}

.plus .pi-plus,
.minus .pi-minus {
  font-size: 0.7em;
}

.plusRow {
  background-color: hsl(1, 100%, 99%);
  padding: 0.5em 0;
}

.plusRow + .plusRow {
  margin-top: 0.5em;
}

.ant-select-selection-item {
  height: 80px !important;
}
</style>

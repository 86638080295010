const EditShowcaseRequest = require('../../EditShowcaseRequest')

class EditVerticalRequest extends EditShowcaseRequest {

  /**
   *
   * @param ratio
   * @param columnCount
   * @return {EditVerticalRequest}
   */
  setBody(ratio, columnCount) {
    this.ratio = parseFloat(ratio)
    this.columnCount = parseInt(columnCount)
    return this

  }

  /**
   * @return {object}
   */
  toParams() {

    return this.toParamsForEdit({ratio:this.ratio, column_count: this.columnCount})

  }
}

module.exports = EditVerticalRequest;

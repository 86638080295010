<template>
  <div class="input-wrap">
    <label>{{ label }} <span v-if="required" class="required-star">*</span> </label>
    <select
      name="select"
      id="select"
      v-model="value"
      @change="$emit('update:modelValue', value)"
      class="form-control"
      :disabled="disabled"
    >
      <option :value="item.value" v-for="(item, index) in items" :key="index">
        {{ item.label }}
      </option>
    </select>

    <!--    <a-select :mode="mode" style="width: 100%"-->
    <!--              v-model:value="value"-->
    <!--              :disabled="disabled"-->
    <!--              :options="items"-->
    <!--              :placeholder="label"-->
    <!--              @change="$emit('update:modelValue', value)"-->
    <!--    />-->

    <div v-if="hasError" class="error-msg">{{ errorMessage }}</div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import useGlobal from '../composables/useGlobal'

export default {
  props: {
    modelValue: String,
    label: String,
    items: Array, // getId ve getName fonksiyonları implemente edilmiş objelerin array'ı şeklinde bir veri tipi gerekiyor.
    disabled: Boolean,

    mode: {
      type: String,
      default: 'tags', // multiple ya da single
    },

    required: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: String,
      default: 'En az bir kayıt seçiniz.',
    },
  },

  setup(props) {
    const { t } = useGlobal()

    const requiredError = computed(() => {
      return 'test'
    })

    const value = ref(props.modelValue)

    const items = props.items.map((item) => {
      return {
        value: item.id,
        label: item.name,
      }
    })

    return {
      value,
      items,
      requiredError,
      t,
    }
  },
}
</script>

<template>
  <div class="card WidgetDetail">
    <div class="row">
      <div class="col-lg-4">
        <div class="input-wrap">
          <label>{{ t('title') }}</label>
          <input
            :disabled="headerTitleDisabled"
            class="form-control"
            v-model="state.headerTitle"
            type="text"
          />
          <div v-if="isHeaderTitleInvalid" class="error-msg">
            {{ t('required.title') }}
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="input-wrap">
          <label>{{ t('rightTitle') }}</label>
          <input
            :disabled="headerRightTitleDisabled"
            class="form-control"
            v-model="state.headerRightTitle"
            type="text"
          />
          <div v-if="isHeaderRightTitleInvalid" class="error-msg">
            {{ t('required.rightTitle') }}
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <TagsInput
          :items="collections"
          :label="t('headerRightShowcaseCollections')"
          v-model="state.headerRightShopifyCollectionId"
          :disabled="!state.headerRightVisible || !state.headerVisible"
        />
      </div>
      <div class="col-lg-4 mb-3" style="display: flex">
        <div class="input-wrap">
          <MySwitch
            v-model="state.headerVisible"
            :label="t('headerIsVisible')"
          />
        </div>
      </div>

      <div class="col-lg-4 mb-3" style="display: flex">
        <div class="input-wrap">
          <MySwitch
            :disabled="headerRightVisibleDisabled"
            v-model="state.headerRightVisible"
            :label="t('rightHeaderIsVisible')"
          />
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <div class="input-wrap">
              <label>{{ t('columnCount') }}</label>
              <a-radio-group v-model:value="state.columnCount">
                <a-radio :value="1">1</a-radio>
                <a-radio :value="2">2</a-radio>
                <a-radio :value="3">3</a-radio>
              </a-radio-group>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="input-wrap">
              <label>{{ t('ratio') }}</label>
              <input class="form-control" v-model="state.ratio" type="text"/>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-lg-6 mt-3">
            <div class="input-wrap">
              <a class="btn btn-danger" @click="cancel">{{ t('cancel') }}</a>
            </div>
          </div>
          <div class="col-lg-6 mt-3">
            <div class="input-wrap">
              <a
                :disabled="isFormInvalid || onSubmitDisable"
                @click="onSubmit"
                class="btn btn-success"
              >{{ t('save') }}</a
              >
            </div>
          </div>
        </div>
      </div>

      <!--  state.existedItems  -->
      <div class="container mt-4">
        <div
          class="row plusRow"
          v-for="(item) in state.existedItems"
          :key="item.id"
        >
          <div class="col-lg-5">
            <div class="input-wrap">
              <TagsInput
                :items="collections"
                :label="t('showcaseCollections')"
                v-model="item.shopify_collection_id"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input-wrap">
              <ImageInput
                :has-error="false"
                v-model="item.image"
                :label="t('showcaseImg')"
              />
            </div>
          </div>

          <div class="col-lg-12">
            <div class="bottom-btn-set">
              <ConfirmModal
                v-if="item.showConfirmModal"
                @cancel="item.toggleConfirmModal()"
                @confirm="item.confirmDelete(item.id)"
                :title="t('AreYouSureDelete')"
                :body="t('AreYouSureDeleteItem')"
              />

              <a
                @click="item.editSubmitExistedChild()"
                class="btn btn-outline-success"
                :title="t('save')"
              >
                <i class="pi pi-save"></i> {{ t('save') }}
              </a>

              <a
                v-if="!item.showConfirmModal"
                @click="item.toggleConfirmModal()"
                class="btn btn-outline-danger"
                :title="t('delete')"
              >
                <i class="pi pi-trash"></i> {{ t('delete') }}
              </a>
            </div>

          </div>
        </div>
      </div>

      <!--   plus   -->
      <div class="container mt-2">
        <div
          class="row plusRow"
          v-for="(item, index) in state.newItems"
          :key="index"
        >
          <div class="col-lg-6">
            <div class="input-wrap">
              <TagsInput
                :items="collections"
                :label="t('showcaseCollections')"
                v-model="item.shopify_collection_id"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input-wrap">
              <ImageInput v-model="item.image" :label="t('showcaseImg')"/>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="bottom-btn-set">
              <a
                @click="item.add"
                :title="t('create.create')"
                class="btn btn-outline-primary"
                :class="{'disabled': item.image.name === '' || item.shopify_collection_id === ''}"
              >
                <i class="pi pi-send"></i> {{ t('create.create') }}
              </a>
              <a
                @click="item.delete(index)"
                :title="t('delete.delete')"
                class="btn btn-outline-danger"
              >
                <i class="pi pi-trash"></i> {{ t('delete') }}
              </a>
            </div>

          </div>
        </div>
      </div>

      <div class="part mt-3" style="display: flex;">
        <div class="input-wrap">
          <a
            @click="addNewItemRow"
            :title="t('add')"
            class="btn btn-outline-primary plus"
          >
            <i class="pi pi-plus"></i> {{ t('addNewItem') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MySwitch from '../../MySwitch'
import ImageInput from '../../ImageInput'
import TagsInput from '../../TagsInput'
import ConfirmModal from '../../ConfirmModal'
import { computed, reactive, ref, watch } from 'vue'
import useGlobal from '../../../composables/useGlobal'
import { required, requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import EditVerticalRequest from '../../../service/RestApi/ShowcaseRequest/Edit/EditVerticalRequest'
import AddShowcaseChildRequest from '../../../service/RestApi/ShowcaseChildRequest/Add/AddShowcaseChildRequest'

export default {
  components: { ConfirmModal, TagsInput, MySwitch, ImageInput },
  props: {
    item: {
      type: Object,
      required: true,
    },
    collections: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { t, restApi, successNotify, errorNotify, router, route, emitter } = useGlobal()

    const showcaseItem = reactive(props.item)

    const onSubmitDisable = ref(false)

    const state = reactive({
      headerTitle: showcaseItem.header.title,
      headerVisible: showcaseItem.header.visible,
      headerRightTitle: showcaseItem.header.right.title,
      headerRightVisible: showcaseItem.header.right.visible,
      headerRightShopifyCollectionId: showcaseItem.header.right.data.shopify_collection_id,
      ratio: showcaseItem.body.ratio,
      columnCount: showcaseItem.body.column_count,

      existedItems: [],
      newItems: [],
    })

    const payload = reactive({
      id: showcaseItem.id,
      is_active: showcaseItem.is_active,
      type: showcaseItem.type,
      shopify_collection_id: showcaseItem.shopify_collection_id,
      header: {
        title: state.headerTitle,
        visible: state.headerVisible,
        right: {
          title: state.headerRightTitle,
          visible: state.headerRightVisible,
          type: 'SHOPIFY_COLLECTION',
          data: {
            shopify_collection_id: state.headerRightShopifyCollectionId,
          },
        },
      },
      body: showcaseItem.body,
    })

    watch(state, () => {
      payload.header.title = state.headerTitle
      payload.header.visible = state.headerVisible
      payload.header.right.title = state.headerRightTitle
      payload.header.right.visible = state.headerRightVisible
      payload.body.ratio = state.ratio

      emitter.emit('showcase-item-updated-sync', payload)

      onSubmitDisable.value = false
    })

    showcaseItem.body.items.forEach((item) => {
      const existedItem = {
        id: item.id,
        title: item.title,
        shopify_collection_id: item.data.shopify_collection_id,
        image: {
          name: '',
          url: item.image_url,
          data: '',
          has_changed: false,
        },
        showConfirmModal: ref(false),
      }

      existedItem.toggleConfirmModal = () => {
        existedItem.showConfirmModal.value =
          ! existedItem.showConfirmModal.value
      }

      existedItem.confirmDelete = () => {
        existedItem.toggleConfirmModal()
        restApi.deleteShowcaseItemChild(existedItem.id).then(() => {

          successNotify(t('showcaseItemDeleted'), t('showcaseItemDeletedDescription'))

          router.go()
        }).catch(() => {
          errorNotify(t('showcaseItemNotDeleted'), t('showcaseItemNotDeletedDescription'))

        })
      }

      existedItem.editSubmitExistedChild = () => {

        existedItem.item_id = route.params.id
        existedItem.image = existedItem.image.value
        if (existedItem.image) {
          existedItem.image_changed = existedItem.image.has_changed
        } else {
          existedItem.image_changed = false
        }
        existedItem.showcase_item_child_id = existedItem.id

        restApi.editShowcaseItemChildSubmit(existedItem).then(() => {

          emitter.emit('refresh-simulation')

          successNotify(t('showcaseItemUpdated'),
            t('showcaseItemUpdatedDescription'))

          router.go()
        }).catch((error) => {

          errorNotify(t('showcaseItemNotUpdated'),
            t('showcaseItemNotUpdatedDescription') + error)

        })
      }

      state.existedItems.push(existedItem)
    })

    const headerTitleDisabled = computed(() => {
      return ! state.headerVisible
    })

    const headerRightTitleDisabled = computed(() => {
      return ! state.headerVisible || ! state.headerRightVisible
    })

    const headerRightVisibleDisabled = computed(() => {
      return ! state.headerVisible
    })

    const addNewItemRow = () => {
      const newItem = {
        type: 'SHOPIFY_COLLECTION',
        item_id: route.params.id,
        title: '',
        shopify_collection_id: '',
        image: {
          name: '',
          url: '',
          data: '',
          has_changed: false,
        },
      }

      newItem.delete = (index) => {
        state.newItems.splice(index, 1)
      }

      newItem.add = () => {

        newItem.image = newItem.image.value

        const addShowcaseChildRequest = new AddShowcaseChildRequest(
          newItem.item_id,
          newItem.type,
          newItem.image,
          newItem.shopify_collection_id,
        ).toParams()

        restApi.addShowcaseItemChild(addShowcaseChildRequest).then(() => {
          emitter.emit('refresh-simulation')

          successNotify(
            t('showcaseItemCreated'),
            t('showcaseItemCreatedDescription'),
          )

          router.go()
        }).catch((error) => {

          errorNotify(
            t('showcaseItemNotCreated'),
            t('showcaseItemNotCreatedDescription') + error,
          )

        })
      }

      state.newItems.push(newItem)
    }

    const isFormInvalid = computed(() => {
      return $v.value.$invalid
    })

    const isHeaderTitleInvalid = computed(() => {
      return $v.value.headerTitle.$error
    })

    const isHeaderRightTitleInvalid = computed(() => {
      return $v.value.headerRightTitle.$error
    })

    const rules = {
      headerTitle: [requiredIf(() => state.headerVisible)],
      headerRightTitle: [
        requiredIf(() => state.headerRightVisible && state.headerVisible),
      ],
      headerRightShopifyCollectionId: [
        requiredIf(() => state.headerRightVisible && state.headerVisible),
      ],
      ratio: [required],
    }

    const $v = useVuelidate(rules, state, { $autoDirty: true })

    const onSubmit = () => {
      const editVerticalRequest = new EditVerticalRequest(
        route.params.id,
        state.headerTitle,
        state.headerVisible,
        state.headerRightTitle,
        state.headerRightVisible,
        state.headerRightShopifyCollectionId,
      ).setBody(state.ratio, state.columnCount).toParams()

      restApi.editShowcase(editVerticalRequest).then(() => {

        successNotify(t('showcaseItemUpdated'),
          t('showcaseItemUpdatedDescription'))

        emitter.emit('refresh-simulation')
        onSubmitDisable.value = false
      }).catch((error) => {
        onSubmitDisable.value = false

        errorNotify(t('showcaseItemNotUpdated'),
          t('showcaseItemNotUpdatedDescription') + error)

      })
    }

    const cancel = () => {
      router.replace({ name: 'HomePage.Allshowcases.Page' })
      emit('update')
    }

    return {
      t,
      onSubmit,
      cancel,
      state,
      isFormInvalid,
      isHeaderTitleInvalid,
      isHeaderRightTitleInvalid,

      addNewItemRow,
      headerTitleDisabled,
      headerRightTitleDisabled,
      headerRightVisibleDisabled,

      onSubmitDisable,
    }
  },
}
</script>

<style scoped>
.plus,
.minus {
  padding: 1px 8px;
}

.plus .pi-plus,
.minus .pi-minus {
  font-size: 0.7em;
}

.plusRow {
  background-color: hsl(1, 100%, 99%);
  padding: 0.5em 0;
}

.plusRow + .plusRow {
  margin-top: 0.5em;
}

.bottom-btn-set {
  display: flex;
  justify-content: center;
  gap: 1em;
}
</style>

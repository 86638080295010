class EditSocialMediasRequest {

  constructor(showcaseItemId, headerTitle, headerVisible, items) {
    this.showcaseItemId = showcaseItemId || ''
    this.headerTitle = headerTitle || ''
    this.headerVisible = headerVisible || false
    this.items = items || []
  }

  toParams() {
    return {
      showcase_item_id: this.showcaseItemId,
      header: {
        title: this.headerTitle,
        visible: this.headerVisible
      },
      body: {
        items: this.items
      }
    }
  }

}

module.exports = EditSocialMediasRequest

module.exports = {
  showcase_item_type: {
    VERTICAL: 'VERTICAL',
    HORIZONTAL: 'HORIZONTAL',
    SOCIAL_MEDIAS: 'SOCIAL_MEDIAS',
    LAYOUT1: 'LAYOUT1',
    LAYOUT2: 'LAYOUT2',
    LAYOUT3: 'LAYOUT3',
  },
  source_type: {
    SHOPIFY_COLLECTION: 'SHOPIFY_COLLECTION',
    SHOPIFY_PRODUCTS: 'SHOPIFY_PRODUCTS'

  }
}


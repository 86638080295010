const EditShowcaseRequest = require('../../EditShowcaseRequest')

class EditHorizontalRequest extends EditShowcaseRequest {

  setBody(ratio) {

    this.ratio = parseFloat(ratio)

    return this

  }

  toParams() {

    return this.toParamsForEdit({ratio: this.ratio})

  }
}

module.exports = EditHorizontalRequest;

<template>
  <div class="input-wrap">
    <label>
      <a-switch :disabled="disabled" @change="handleChange" v-model:checked="content"/>
      {{ label }}
    </label>
  </div>
</template>

<script>

export default {

  props: {
    modelValue: Boolean,
    label: String,
    disabled: Boolean,
  },

  data() {
    return {
      content: this.modelValue
    }
  },

  methods: {
    handleChange(checked) {
      this.$emit('update:modelValue', checked)
    }
  }

}

</script>

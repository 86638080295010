<template>
  <div class="wrapper">
    <div class="body">
      <div class="header">
        <h5>{{ title }}</h5>
      </div>
      <div class="content">
        <p>{{ body }}</p>
      </div>
      <div class="actions">
        <button class="btn btn-outline-primary" @click="$emit('cancel')">{{ t('cancel') }}</button>
        <button class="btn btn-danger" @click="$emit('confirm')">{{ t('delete') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import useGlobal from '../composables/useGlobal'

export default {
  name: 'ConfirmModal',
  props: {
    title: String,
    body: String,
    okText: String,
    cancelText: String,
  },
  setup() {
    const { t } = useGlobal()

    return {
      t,
    }
  },
}
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  margin-top: 1em;
}

.wrapper {
  position: absolute;
  z-index: 1000;
  display: flex;
}

.body {
  background: #fff;
  border-radius: 0.5em;
  padding: 2em;
  position: absolute;
  top: -10em;
  right: 0;
  z-index: 1000;
  max-width: 30em;
  width: max-content;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: .5em 1em;
}

/* responsive mobile */
@media (max-width: 768px) {
  .body {
    right: -110px;
  }
}
</style>

<template>
  <div>
    <div class="input-wrap">
      <label :for="componentId">{{ label }}
        <span v-if="required" class="requiredStar">*</span>
      </label>
      <input :class="{'image-selected':hasImage}" class="form-control" :id="componentId" :key="componentId" type="file"
             @change="imageChanged"/>
    </div>

    <div v-if="hasError" class="error-msg">{{ t('required.image') }}</div>

    <div class="imgBox" v-if="hasImage">
      <img :src="getImageSrc" alt="selected image"/>
    </div>

  </div>

</template>

<script>

import { computed, reactive, ref } from 'vue'
import { v4 } from 'uuid'
import isEmpty from 'is-empty'
import useGlobal from '../composables/useGlobal'

export default {

  props: {
    label: String,
    modelValue: {
      type: Object, // name, data ve url, has_changed(false) içeren bir object
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {

    const { t } = useGlobal()

    const componentId = ref(v4())

    const image = ref(props.modelValue)

    const state = reactive({
      selectedImageData: props.modelValue,
    })

    const hasImage = computed(() => {
      return ! isEmpty(image.value.data) || ! isEmpty(image.value.url)
    })

    const getImageSrc = computed(() => {
      console.log('image')
      console.log(image.value)
      console.log(image.value.url)

      if ( ! isEmpty(image.value.data)) {
        return image.value.data
      }

      if ( ! isEmpty(image.value.url)) {
        return image.value.url
      }
      return ''
    })

    const imageChanged = (event) => {

      if (event.target.files) {

        const selectedFile = event.target.files[0]

        image.value.name = selectedFile.name
        image.value.has_changed = true

        const reader = new FileReader()

        reader.onload = event => {

          image.value.data = event.target.result

          emit('update:modelValue', image)

        }

        reader.readAsDataURL(selectedFile)

      }

    }

    const removeImage = () => {

      image.value.name = ''
      image.value.data = ''
      image.value.url = ''
      image.value.has_changed = true

      componentId.value = v4()

    }

    return {
      componentId,

      state,

      hasImage,
      getImageSrc,

      imageChanged,
      removeImage,
      t,

    }
  },
}

</script>

<style scoped>
.btnRemoveImage {
  position: absolute;
  top: 0;
  right: 20px;
  color: darkred;
}

.btnRemoveImage i {
  font-size: 25px;
  background-color: #fff;
  border-radius: 50%;
}

.image-selected {
  position: relative;
  overflow: hidden;
}

.image-selected::after {
  content: '';
  position: absolute;
  inset: 0;
  background-color: #fff;
  left: 25%;
}

</style>

const EditShowcaseRequest = require('../../EditShowcaseRequest')
const enums = require("../../Enums");

class EditLayout2Request extends EditShowcaseRequest {


  /**
   *
   * @param perPage
   * @param pageCount
   * @param sortKey
   * @param shopifyCollectionId
   * @return {EditLayout2Request}
   */
  setBody(perPage, pageCount, sortKey, shopifyCollectionId) {
    this.perPage = parseInt(perPage)
    this.pageCount = parseInt(pageCount)
    this.sortKey = sortKey || ''
    this.shopifyCollectionId = shopifyCollectionId || ''
    return this
  }

  /**
   *
   * @return {object}
   */
  toParams() {

    return this.toParamsForEdit({
      per_page: this.perPage,
      page_count: this.pageCount,
      sort_key: this.sortKey,
      source_type: enums.source_type.SHOPIFY_COLLECTION,
      source_data: {
        shopify_collection_id: this.shopifyCollectionId
      }
    })
  }
}

module.exports = EditLayout2Request;

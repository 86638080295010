class AddShowcaseChildRequest {
  /**
   *
   * @param showcaseItemId
   * @param type
   * @param image
   * @param shopifyCollectionId
   */
  constructor(showcaseItemId, type, image, shopifyCollectionId) {
    this.showcaseItemId = showcaseItemId
    this.type = type
    this.image = image
    this.shopifyCollectionId = shopifyCollectionId
  }

  toParams() {
    return {
      item_id: this.showcaseItemId,
      type: this.type,
      image: this.image,
      shopify_collection_id: this.shopifyCollectionId
    }
  }
}

module.exports = AddShowcaseChildRequest

<template>
  <div class="cases">
    <div v-if="isLoading" class="loading">
      <div class="spinner"></div>
      <div class="text">{{ t('loading.loading') }}</div>
    </div>

    <div v-else>
      <div v-if="hasError">
        <h3>{{ t('detailNotLoaded') }}</h3>
        <p>{{ t('tryAgain') }}</p>
      </div>

      <div v-else>
        <div class="case">
          <Horizontal
            :collections="collections"
            :item="showcase"
            v-if="showcase.type === 'HORIZONTAL'"
          />

          <Vertical
            :collections="collections"
            :item="showcase"
            v-if="showcase.type === 'VERTICAL'"
          />

          <Layout1
            :collections="collections"
            :item="showcase"
            v-if="showcase.type === 'LAYOUT1'"
          />

          <Layout2
            :collections="collections"
            :item="showcase"
            v-if="showcase.type === 'LAYOUT2'"
          />

          <Layout3
            :collections="collections"
            :item="showcase"
            v-if="showcase.type === 'LAYOUT3'"
          />

          <SocialMedia
            :item="showcase"
            v-if="showcase.type === 'SOCIAL_MEDIAS'"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import Horizontal from './EditShowCases/Horizontal'
import Layout1 from './EditShowCases/Layout1'
import Layout2 from './EditShowCases/Layout2'
import Layout3 from './EditShowCases/Layout3'
import SocialMedia from './EditShowCases/SocialMedia'
import Vertical from './EditShowCases/Vertical'
import useGlobal from '../../composables/useGlobal'

export default {
  components: {
    Horizontal,
    Vertical,
    Layout1,
    Layout2,
    Layout3,
    SocialMedia,
  },
  props: {
    collections: Array,
  },
  setup() {
    const { route, t, errorNotify, restApi, emitter } = useGlobal()

    const isLoading = ref(false)
    const hasError = ref(false)

    const showcase = ref({
      type: '',
    })

    const fetchShowcaseDetail = (id) => {
      isLoading.value = true

      return restApi.getShowcaseById(route.params.id ? route.params.id : id).then((response) => {
        isLoading.value = false

        showcase.value = response
      }).catch(() => {
        isLoading.value = false
        hasError.value = true

        errorNotify(t('showcasesNotLoaded'), t('tryAgain'))
      })
    }

    onMounted(() => {
      fetchShowcaseDetail()
    })

    return {
      route,
      t,
      showcase,

      isLoading,
      hasError,
    }
  },
}
</script>

<style scoped>
.case {
  position: relative;
  overflow: hidden;
}

.loading {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes loading {
  0% {
    border: 4px solid #fff;
  }
  50% {
    border: 4px solid gray;
  }
  100% {
    border: 4px solid #fff;
  }
}
</style>

class EditShowcaseRequest {

  constructor(showcaseItemId, headerTitle, headerVisible, headerRightTitle, headerRightVisible, headerRightShopifyCollectionId) {

    this.showcaseItemId = showcaseItemId;
    this.headerTitle = headerTitle;
    this.headerVisible = headerVisible;
    this.headerRightTitle = headerRightTitle;
    this.headerRightVisible = headerRightVisible;
    this.headerRightShopifyCollectionId = headerRightShopifyCollectionId;

  }

  /**
   * @param body
   * @return {object}
   */
  toParamsForEdit(body) {

    return {
      showcase_item_id: this.showcaseItemId,
      header: {
        title: this.headerTitle,
        visible: this.headerVisible,
        right: {
          title: this.headerRightTitle,
          visible: this.headerRightVisible,
          data: {
            shopify_collection_id: this.headerRightShopifyCollectionId
          }
        }
      },
      body: body
    }
  }
}

module.exports = EditShowcaseRequest;

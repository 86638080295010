<template>
  <div class="card">
    <div class="row">
      <div class="col-lg-12">
        <div class="input-wrap">
          <label>{{ t('title') }}</label>
          <input
            v-model="state.headerTitle"
            class="form-control"
            type="text"
            name="title"
            id="title"
            :disabled="! state.headerVisible"
          />
          <div v-if="isHeaderTitleInvalid" class="error-msg">
            {{ t('required.title') }}
          </div>
        </div>
      </div>

      <div class="col-lg-12" style="display: flex">
        <div class="input-wrap">
          <MySwitch
            v-model="state.headerVisible"
            :label="t('headerIsVisible')"
          />
        </div>
      </div>

      <!-- current child-->

      <div class="container mt-2">
        <div
          class="row plusRow"
          v-for="(item, index) in state.existedItems"
          :key="index"
        >
          <div class="col-lg-11">
            <div class="row">
              <div class="col-lg-6">
                <div class="input-wrap">
                  <label for="title">{{ t('title') }}
                    <span v-if="index > 0">{{ index }}</span></label>
                  <input
                    class="form-control"
                    v-model="item.title"
                    type="text"
                    name="title"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="input-wrap">
                  <label>{{ t('platform') }}
                    <span v-if="index > 0">{{ index }}</span></label>
                  <select
                    v-model="item.code"
                    name="code"
                    id="code"
                    class="form-control"
                  >
                    <option
                      :value="option.code"
                      v-for="(option, index) in state.icons"
                      :key="index"
                    >
                      {{ option.code }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="input-wrap">
                  <label>Url <span v-if="index > 0">{{ index }}</span></label>
                  <input
                    v-model="item.url"
                    type="text"
                    placeholder="https://"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-1 rightBtnSet">
            <a
              @click="deleteExistedItem(index)"
              :title="t('delete')"
              class="btn btn-outline-danger"
            >
              <i class="pi pi-trash"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="part mt-3" style="display: flex; justify-content: flex-end">
        <div class="input-wrap">
          <a
            @click="addNewItemRow"
            :title="t('add')"
            class="btn btn-outline-primary plus"
          >
            <i class="pi pi-plus"></i> {{ t('addNewItem') }}
          </a>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-lg-6 mt-3">
            <div class="input-wrap">
              <a class="btn btn-danger" @click="cancel">{{ t('cancel') }}</a>
            </div>
          </div>

          <div class="col-lg-6 mt-3">
            <div class="input-wrap">
              <a :disabled="isFormInvalid || onSubmitDisable" @click="onSubmit" class="btn btn-success">
                {{ t('save') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import MySwitch from '../../MySwitch'
import useGlobal from '../../../composables/useGlobal'
import { requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import EditSocialMediasRequest from '../../../service/RestApi/ShowcaseRequest/Edit/EditSocialMediasRequest'

export default {
  components: { MySwitch },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {

    const { t, route, router, restApi, successNotify, errorNotify, emitter } = useGlobal()

    const showcaseItem = reactive(props.item)

    const state = reactive({
      headerTitle: showcaseItem.header.title,
      headerVisible: showcaseItem.header.visible,

      existedItems: showcaseItem.body.items,

      icons: [],
    })

    const isFormInvalid = computed(() => {
      return $v.value.$invalid
    })

    const isHeaderTitleInvalid = computed(() => {
      return $v.value.headerTitle.$error
    })

    const rules = {
      headerTitle: [requiredIf(() => state.headerVisible)],
    }

    const $v = useVuelidate(rules, state, { $autoDirty: true })

    const addNewItemRow = () => {
      const newItem = {
        title: '',
        code: '',
        url: '',
      }

      newItem.confirmDelete = () => {
        restApi.deleteShowcaseItemChild(newItem.id).then(() => {
          successNotify(
            'Vitrin öğesi silindi',
            'Vitrin öğesi başarıyla silindi.',
          )
          router.go()
        }).catch((error) => {
          errorNotify(
            'Vitrin öğesi silinemedi',
            'Vitrin öğesi silinirken bir hata oluştu.' + error,
          )
        })
      }

      newItem.delete = (index) => {
        state.newItems.splice(index, 1)
      }

      state.existedItems.push(newItem)
    }

    const deleteExistedItem = (index) => {
      state.existedItems.splice(index, 1)
    }

    const payload = reactive({
      id: showcaseItem.id,
      is_active: showcaseItem.is_active,
      type: showcaseItem.type,
      header: {
        title: state.headerTitle,
        visible: state.headerVisible,
      },
      body: showcaseItem.body,
    })

    watch(state, () => {
      payload.header.title = state.headerTitle
      payload.header.visible = state.headerVisible

      emitter.emit('showcase-item-updated-sync', payload)

      onSubmitDisable.value = false
    })

    const cancel = () => {
      router.replace({ name: 'HomePage.Allshowcases.Page' })
      emit('update')
    }

    const onSubmitDisable = ref(false)

    const onSubmit = () => {
      const editSocialMediasRequest = new EditSocialMediasRequest(
        route.params.id,
        state.headerTitle,
        state.headerVisible,
        state.existedItems,
      ).toParams()

      restApi.editShowcase(editSocialMediasRequest).then(() => {

        successNotify(t('changesSaved'), t('changesSavedDescription'))

        emitter.emit('refresh-simulation')
        onSubmitDisable.value = true
      }).catch((error) => {

        errorNotify(t('changesNotSaved'), t('changesNotSavedDescription') + error)

        onSubmitDisable.value = true

      })
    }

    const icons = ref({})

    onMounted(() => {
      restApi.fetchIcons().then((response) => {
        state.icons = response
      })
    })

    return {
      t,
      router,
      state,
      cancel,
      onSubmit,
      isFormInvalid,
      isHeaderTitleInvalid,
      icons,

      addNewItemRow,
      showcaseItem,
      deleteExistedItem,
      onSubmitDisable,
    }
  },
}
</script>

<style scoped>
.plus {
  padding: 1px 8px;
}

.plus .pi-plus {
  font-size: 0.7em;
}

.plusRow {
  padding: 1em 0 1em 1em;
  margin-bottom: 1em;
}

.plusRow:nth-child(odd) {
  background-color: #f7f7f778;
}
</style>

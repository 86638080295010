const EditShowcaseRequest = require('../../EditShowcaseRequest')
const enums = require("../../Enums");

class EditLayout1Request extends EditShowcaseRequest {

  /**
   *
   * @param sortKey
   * @param sourceType
   * @param itemCount
   * @param shopifyCollectionId
   * @param shopifyProductIds
   * @return {EditLayout1Request}
   */

  setBody(sortKey, sourceType, itemCount, shopifyCollectionId = '', shopifyProductIds = []) {
    this.sortKey = sortKey || ''
    this.sourceType = sourceType || ''
    this.itemCount = parseInt(itemCount)
    this.shopifyCollectionId = shopifyCollectionId || ''
    this.shopifyProductIds = shopifyProductIds || []
    return this
  }

  /**
   * @return {object}
   */
  toParams() {

    const body = {}

    switch (this.sourceType) {

      case enums.source_type.SHOPIFY_COLLECTION:

        body.source_type = this.sourceType

        body.source_data = {
          shopify_collection_id: this.shopifyCollectionId
        }

        break

      case enums.source_type.SHOPIFY_PRODUCTS:

        body.source_type = this.sourceType

        body.source_data = {

          shopify_product_ids: this.shopifyProductIds

        }

        break
    }

    body.item_count = this.itemCount

    body.sort_key = this.sortKey

    return this.toParamsForEdit(body)

  }
}

module.exports = EditLayout1Request;

<template>
  <div class="card WidgetDetail">
    <div class="row">
      <div class="col-lg-4">
        <div class="input-wrap">
          <label for="title">{{ t('title') }}</label>
          <input
            :disabled="headerTitleDisabled"
            class="form-control"
            v-model="state.headerTitle"
            type="text"
            name="title"
            id="title"
          />
          <div v-if="isHeaderTitleInvalid" class="error-msg">
            {{ t('required.title') }}
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="input-wrap">
          <label for="subTitle">{{ t('rightTitle') }}</label>
          <input
            :disabled="headerRightTitleDisabled"
            class="form-control"
            v-model="state.headerRightTitle"
            type="text"
            name="subTitle"
            id="subTitle"
          />
          <div v-if="isHeaderRightTitleInvalid" class="error-msg">
            {{ t('required.rightTitle') }}
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <TagsInput
          :items="collections"
          v-model="state.headerRightShopifyCollectionId"
          :label="t('headerRightShowcaseCollections')"
          :disabled="headerRightShopifyCollectionIdDisabled"
        />
      </div>
      <div v-if="isHeaderRightTitleInvalid" class="error-msg">
        {{ t('required.collection') }}
      </div>

      <div class="col-lg-4 mb-3" style="display: flex">
        <div class="input-wrap">
          <MySwitch
            v-model="state.headerVisible"
            :label="t('headerIsVisible')"
          />
        </div>
      </div>
      <div class="col-lg-4 mb-3" style="display: flex">
        <div class="input-wrap">
          <MySwitch
            :disabled="headerRightVisibleDisabled"
            v-model="state.headerRightVisible"
            :label="t('rightHeaderIsVisible')"
          />
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <div class="input-wrap">
              <label>{{ t('pageCount') }}</label>
              <a-radio-group v-model:value="state.pageCount">
                <a-radio :value="2">2</a-radio>
                <a-radio :value="3">3</a-radio>
                <a-radio :value="4">4</a-radio>
              </a-radio-group>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input-wrap">
              <label>{{ t('perPage') }}</label>
              <a-radio-group v-model:value="state.perPage">
                <a-radio :value="3">3</a-radio>
                <a-radio :value="4">4</a-radio>
              </a-radio-group>
            </div>
          </div>

          <div class="col-lg-6">
            <TagsInput
              :items="collections"
              v-model="state.shopifyCollectionId"
              :label="t('showcaseCollections')"
            />
          </div>

          <div class="col-lg-6">
            <div class="input-wrap">
              <label>{{ t('sort.sort') }}</label>
              <a-select
                ref="select"
                v-model:value="state.sortKey"
                style="width: 100%"
              >
                <a-select-option value="DEFAULT">{{
                    t('sort.default')
                  }}
                </a-select-option>
                <a-select-option value="CREATED_AT_DESC">{{
                    t('sort.backToOld')
                  }}
                </a-select-option>
                <a-select-option value="PRICE_ASC">{{
                    t('sort.increasedPrice')
                  }}
                </a-select-option>
                <a-select-option value="PRICE_DESC">{{
                    t('sort.decreasingPrice')
                  }}
                </a-select-option>
                <a-select-option value="TITLE_DESC">{{
                    t('sort.alphabeticalAZ')
                  }}
                </a-select-option>
                <a-select-option value="TITLE_ASC">{{
                    t('sort.alphabeticalZA')
                  }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-lg-6 mt-3">
            <div class="input-wrap">
              <a class="btn btn-danger" @click="cancel">{{ t('cancel') }}</a>
            </div>
          </div>
          <div class="col-lg-6 mt-3">
            <div class="input-wrap">
              <a
                :disabled="isFormInvalid"
                @click="onSubmit"
                class="btn btn-success"
              >{{ t('save') }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, watch, ref } from 'vue'
import MySwitch from '../../MySwitch'
import TagsInput from '../../TagsInput'
import useGlobal from '../../../composables/useGlobal'
import { requiredIf, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import EditLayout2Request from '../../../service/RestApi/ShowcaseRequest/Edit/EditLayout2Request'

export default {
  components: {
    TagsInput,
    MySwitch,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    collections: {
      type: Object,
      required: true,
    },
  },
  setup(props) {

    const { t, restApi, route, router, successNotify, errorNotify, emitter } = useGlobal()

    const showcaseItem = reactive(props.item)

    const onSubmitDisable = ref(false)

    const state = reactive({
      headerTitle: showcaseItem.header.title,
      headerVisible: showcaseItem.header.visible,
      headerRightTitle: showcaseItem.header.right.title,
      headerRightVisible: showcaseItem.header.right.visible,
      headerRightShopifyCollectionId: showcaseItem.header.right.data.shopify_collection_id,
      shopifyCollectionId: showcaseItem.body.shopify_collection_id,
      pageCount: showcaseItem.body.page_count,
      perPage: showcaseItem.body.per_page,
      sortKey: showcaseItem.body.sort_key,
    })

    const payload = reactive({
      id: showcaseItem.id,
      is_active: showcaseItem.is_active,
      type: showcaseItem.type,
      shopify_collection_id: showcaseItem.shopify_collection_id,
      header: {
        title: state.headerTitle,
        visible: state.headerVisible,
        right: {
          title: state.headerRightTitle,
          visible: state.headerRightVisible,
          type: 'SHOPIFY_COLLECTION',
          data: {
            shopify_collection_id: state.headerRightShopifyCollectionId,
          },
        },
      },
      body: showcaseItem.body,
    })

    watch(state, () => {
      payload.header.title = state.headerTitle
      payload.header.visible = state.headerVisible
      payload.header.right.title = state.headerRightTitle
      payload.header.right.visible = state.headerRightVisible
      payload.header.right.data.shopify_collection_id = state.headerRightShopifyCollectionId
      payload.body.item_count = state.itemCount

      emitter.emit('showcase-item-updated-sync', payload)

      onSubmitDisable.value = false
    })

    watch(
      () => state.shopifyCollectionId,
      (shopifyCollectionId) => {
        restApi.getProductsByCollectionsId(
          shopifyCollectionId,
          'LAYOUT2',
          state.pageCount,
        ).then((products) => {
          payload.body = products

          emitter.emit('showcase-item-updated-sync', payload)

        })
      },
    )

    const headerTitleDisabled = computed(() => {
      return ! state.headerVisible
    })

    const headerRightTitleDisabled = computed(() => {
      return ! state.headerVisible || ! state.headerRightVisible
    })

    const headerRightVisibleDisabled = computed(() => {
      return ! state.headerVisible
    })

    const headerRightShopifyCollectionIdDisabled = computed(() => {
      return ! state.headerVisible || ! state.headerRightVisible
    })

    const isFormInvalid = computed(() => {
      return $v.value.$invalid || onSubmitDisable
    })

    const isHeaderTitleInvalid = computed(() => {
      return $v.value.headerTitle.$error
    })

    const isHeaderRightTitleInvalid = computed(() => {
      return $v.value.headerRightTitle.$error
    })

    const rules = {
      headerTitle: [requiredIf(() => state.headerVisible)],
      headerRightTitle: [
        requiredIf(() => state.headerRightVisible && state.headerVisible),
      ],
      headerRightShopifyCollectionId: [
        requiredIf(() => state.headerRightVisible && state.headerVisible),
      ],
      shopifyCollectionId: [required],
    }

    const $v = useVuelidate(rules, state, { $autoDirty: true })

    const onSubmit = () => {
      const editLayout2Request = new EditLayout2Request(
        route.params.id,
        state.headerTitle,
        state.headerVisible,
        state.headerRightTitle,
        state.headerRightVisible,
        state.headerRightShopifyCollectionId,
      ).setBody(
        state.perPage,
        state.pageCount,
        state.sortKey,
        state.shopifyCollectionId,
      ).toParams()

      restApi.editShowcase(editLayout2Request).then(() => {
        emitter.emit('refresh-simulation')

        successNotify(t('changesSaved'), t('changesSavedDescription'))

        onSubmitDisable.value = true
      }).catch((error) => {
        onSubmitDisable.value = true

        errorNotify(t('changesNotSaved'), t('changesNotSavedDescription') + error)

      })
    }

    const cancel = () => {
      emitter.emit('refresh-simulation')
      router.replace({ name: 'HomePage.Allshowcases.Page' })
    }

    return {
      t,
      restApi,

      onSubmit,
      cancel,
      isFormInvalid,
      isHeaderTitleInvalid,
      isHeaderRightTitleInvalid,
      headerTitleDisabled,
      headerRightTitleDisabled,
      headerRightVisibleDisabled,
      state,
      headerRightShopifyCollectionIdDisabled,

      onSubmitDisable,
    }
  },
}
</script>

<style scoped>
.plus,
.minus {
  padding: 1px 8px;
}

.plus .pi-plus,
.minus .pi-minus {
  font-size: 0.7em;
}

.plusRow {
  background-color: hsl(1, 100%, 99%);
  padding: 0.5em 0;
}

.plusRow + .plusRow {
  margin-top: 0.5em;
}
</style>
